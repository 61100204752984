@tailwind base;

@tailwind components;
@import './quiz.css';
@import './primary-newsletter-cta.css';
@import './nprogress.css';

@tailwind utilities;
@import './focus-visible.css';

::selection {
  @apply bg-brand-pink-500 text-white;
}
