[data-sr-convertkit-subscribe-form] {
  @apply space-y-4 w-full;
  [data-sr-input] {
    @apply autofill:bg-white w-full rounded-lg border border-gray-400 focus:ring-2 focus-visible:ring-brand-pink-500 focus:outline-none focus:border-transparent py-3 px-4;
  }
  [data-sr-input-label] {
    @apply block pb-1;
  }
  [data-sr-button] {
    @apply mx-auto;
  }
  [data-sr-input-asterisk] {
    @apply text-pink-500;
  }
}
